<template>
  <infinite-phrases />
</template>

<script>
  import InfinitePhrases from '../components/InfinitePhrases'

  export default {
    name: 'Home',

    components: {
      InfinitePhrases,
    },
  }
</script>
