<template>
  <v-container>
    <div v-for="(item, $index) in list" :key="$index">
      <v-card 
        hover 
        class="mb-3" 
        :height="item.clicked ? null : 125" 
        @click.stop="item.clicked = !item.clicked"
      >
        <v-card-title class="text-truncate">
          {{ item.data[0] }}
        </v-card-title>
        <v-card-subtitle class="font-italic">
          {{ item.data.length }} similar phrases
        </v-card-subtitle>
        <v-card-text :class="item.clicked ? '' : 'd-inline-block text-truncate'">
          {{ item.data.join(' ') }}
        </v-card-text>
      </v-card>
    </div>
    <infinite-loading @infinite="infiniteHandler"></infinite-loading>
  </v-container>
</template>

<script>
  import axios from 'axios';

  const api = '//bachelorspeak.com/api/phrases';

  export default {
    name: 'InfinitePhrases',

    data: () => ({
      page: 1,
      list: []
    }),
    methods: {
      infiniteHandler($state) {
        axios.get(api, {
          params: {
            page: this.page,
          },
        }).then(({ data }) => {
          if (data) {
            this.page += 1;
            this.list.push({
              clicked: false,
              data: [...new Set(data)]
            });
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      },
    },
  }
</script>
