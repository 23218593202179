<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense nav shaped>
        <v-list-item-group
          v-model="selectedLink"
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in menuLinks"
            :key="i"
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>bachelorspeak.com <small>| {{menuLinks[selectedLink].text}}</small></v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view class="mb-4"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: null,
    selectedLink: 0,
    menuLinks: [{
      icon: 'mdi-text',
      text: 'similar phrases',
      to: '/'
    }, {
      icon: 'mdi-matrix',
      text: 'similarity matrix',
      to: '/matrix'
    }, {
      icon: 'mdi-book-open-variant',
      text: 'generated episodes',
      to: '/episodes'
    }, {
      icon: 'mdi-information',
      text: 'wtf is this',
      to: '/about'
    }, {
      icon: 'mdi-gift',
      text: 'donate',
      to: '/donate'
    }]
  }),
};
</script>

<style>
  * {
    font-family: 'Inconsolata', sans-serif;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important 
  }
</style>